import HeadMeta from "components/HeadMeta";
import HomeFeatures from "components/Home/HomeFeatures";
import HomeHero from "components/Home/HomeHero";
export default function Home() {
    return (
        <>
            <HeadMeta />
            <HomeHero />
            <HomeFeatures />
        </>
    );
}
