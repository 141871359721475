import Head from "next/head";


export default function HeadMeta () {
    return(
        <Head>
            <title>Swap2.me</title>
            <meta name='description' content='Receive crypto payments easily' />
        </Head>
    )
}